import {
  SerializedEditorState,
  SerializedLexicalNode,
  SerializedLineBreakNode,
  SerializedParagraphNode,
  SerializedTextNode,
} from "lexical";
import { SerializedHeadingNode, SerializedQuoteNode } from "@lexical/rich-text";
import { SerializedImageNode } from "pages/EditorLexical/nodes/ImageNode";
import styles from "../../pages/Viewer/ViewerMainContainer/ViewerMainSection/ViewerMainSection.module.css";
import styles2 from "../../pages/Viewer/ViewerMainContainer/ViewerMainContainer.module.css";
import ViewerHeader from "./ViewerHeader";
import { useMemo, useState } from "react";
import ViewerSidebar from "./ViewerSidebar";
import { ScenarioData } from "pages/EditorLexical/datastore";
import Pager, { PageItem } from "pages/Viewer/ViewerMainContainer/Pager";

type ViewerLexicalProps = {
  scenarioData: ScenarioData;
  projectName: string;
  projectId: string;
  rev: string;
  bookletId: string;
  onChangeBooklet: (bookletId: string) => void;
  date: string;
  enableShareButton: boolean;
};

export const ViewerLexical = ({
  scenarioData,
  projectName,
  projectId,
  rev,
  bookletId,
  onChangeBooklet,
  date,
  enableShareButton,
}: ViewerLexicalProps) => {
  const [showIndex, setShowIndex] = useState(false);

  const booklets = useMemo(
    () =>
      scenarioData.booklets.map((booklet) => {
        return { id: booklet.id, name: booklet.title };
      }),
    [scenarioData.booklets]
  );

  const index = scenarioData.booklets.findIndex(({ id }) => id === bookletId);
  const booklet = scenarioData.booklets[index];
  const serializedEditorState = booklet?.data;
  const title = booklet?.title || "";

  const prev = toPageItem(scenarioData.booklets[index - 1]);
  const next = toPageItem(scenarioData.booklets[index + 1]);

  return (
    <>
      <ViewerHeader
        projectName={projectName}
        projectId={projectId}
        rev={rev}
        booklets={booklets}
        date={date}
        showIndex={showIndex}
        enableShareButton={enableShareButton}
        toggleShowIndex={() => setShowIndex(!showIndex)}
      />
      <ViewerBody
        serializedEditorState={serializedEditorState}
        projectName={projectName}
        title={title}
        prev={prev}
        next={next}
        changeBooklet={onChangeBooklet}
      />
      <ViewerSidebar
        booklets={scenarioData.booklets}
        currentBookletId={bookletId}
        setCurrentBookletId={onChangeBooklet}
        show={showIndex}
        closeIndex={() => setShowIndex(false)}
      />
    </>
  );
};

const toPageItem = (
  booklet: ScenarioData["booklets"][0] | undefined
): PageItem | undefined => {
  if (booklet) {
    return { id: booklet.id, name: booklet.title };
  }
};

type ViewerBodyProps = {
  serializedEditorState: SerializedEditorState | null | undefined;
  projectName: string;
  title: string;
  prev?: PageItem;
  next?: PageItem;
  changeBooklet: (id: string) => void;
};

export const ViewerBody = ({
  serializedEditorState,
  projectName,
  title,
  prev,
  next,
  changeBooklet,
}: ViewerBodyProps) => {
  if (serializedEditorState == null) {
    return null;
  }

  return (
    <div className={styles2.container}>
      <div className={styles2.inner}>
        <div className={styles.container}>
          <p className={styles.projectTitle}>{projectName}</p>
          <p className={styles.libretto}>{title}</p>
          {serializedEditorState.root.children.map((node, index) => {
            return <Block node={node} index={index} />;
          })}
        </div>
        <Pager prev={prev} next={next} changeBooklet={changeBooklet} />
      </div>
    </div>
  );
};

type BlockProps = {
  node: SerializedLexicalNode;
  index: number;
};

const Block = ({ node, index }: BlockProps) => {
  if (isParagraphNode(node)) {
    return <Paragraph node={node} />;
  }

  if (isHeadingNode(node)) {
    return <Heading node={node} id={index.toString()} />;
  }

  if (isQuoteNode(node)) {
    return <Quote node={node} />;
  }

  if (isImageNode(node)) {
    return <Image node={node} />;
  }

  return null;
};

type ParagraphProps = {
  node: SerializedParagraphNode;
};

const Paragraph = ({ node }: ParagraphProps) => {
  return (
    <div className={styles.txt}>
      <TextNodes nodes={node.children} />
    </div>
  );
};

type HeadingProps = {
  node: SerializedHeadingNode;
  id: string;
};

const Heading = ({ node, id }: HeadingProps) => {
  switch (node.tag) {
    case "h1":
      return (
        <h1 className={styles.h1} id={id}>
          <TextNodes nodes={node.children} />
        </h1>
      );
    case "h2":
      return (
        <h2 className={styles.h2} id={id}>
          <TextNodes nodes={node.children} />
        </h2>
      );
    default:
      return (
        <h3 className={styles.h3} id={id}>
          <TextNodes nodes={node.children} />
        </h3>
      );
  }
};

type QuoteProps = {
  node: SerializedQuoteNode;
};

const Quote = ({ node }: QuoteProps) => {
  return (
    <div className={styles.notes}>
      <TextNodes nodes={node.children} />
    </div>
  );
};

type ImageProps = {
  node: SerializedImageNode;
};

const Image = ({ node }: ImageProps) => {
  return (
    <div className={styles.img}>
      <img src={node.src} data-size={node.size} />
    </div>
  );
};

type TextNodesProps = {
  nodes: SerializedLexicalNode[];
};

const TextNodes = ({ nodes }: TextNodesProps) => {
  return (
    <>
      {nodes.map((node) => (
        <Text node={node} />
      ))}
    </>
  );
};

type TextNodeProp = {
  node: SerializedLexicalNode;
};

const Text = ({ node }: TextNodeProp) => {
  if (isLineBreakNode(node)) {
    const a = node;
    return <br />;
  }

  if (!isTextNode(node)) {
    return null;
  }

  let element = <>{node.text}</>;
  if (isBold(node)) {
    element = <span className={styles.bold}>{element}</span>;
  }
  if (isUnderline(node)) {
    element = <span className={styles.underline}>{element}</span>;
  }

  return element;
};

export const isParagraphNode = (
  node: SerializedLexicalNode
): node is SerializedParagraphNode => {
  return node.type === "paragraph";
};

export const isHeadingNode = (
  node: SerializedLexicalNode
): node is SerializedHeadingNode => {
  return node.type === "heading";
};

export const isQuoteNode = (
  node: SerializedLexicalNode
): node is SerializedQuoteNode => {
  return node.type === "quote";
};

export const isImageNode = (
  node: SerializedLexicalNode
): node is SerializedImageNode => {
  return node.type === "image";
};

export const isTextNode = (
  node: SerializedLexicalNode
): node is SerializedTextNode => {
  return node.type === "text";
};

type SerializedLineBreakNodeStricted = Omit<SerializedLineBreakNode, "type"> & {
  type: "linebreak";
};

export const isLineBreakNode = (
  node: SerializedLexicalNode
): node is SerializedLineBreakNodeStricted => {
  return node.type === "linebreak";
};

const IS_BOLD = 1;
const IS_UNDERLINE = 1 << 3;

export const isBold = (textNode: SerializedTextNode): boolean => {
  return Boolean(textNode.format & IS_BOLD);
};

export const isUnderline = (textNode: SerializedTextNode): boolean => {
  return Boolean(textNode.format & IS_UNDERLINE);
};
